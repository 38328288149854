<template>
  <div class="page">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>智能填报</el-breadcrumb-item>
        <el-breadcrumb-item>普通类型填报</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="title">
      <p class="f1">河南省新高考选科指南</p>
      <p class="f2">数据来源：《河南招生普通高校专业（类）选考科目范围》 <router-link to="/Detail?id=292" class="f3" >详细政策>></router-link> </p>
    </div>

    <div class="nav">
      <div :class="{ act: this.nav_num == 0 }" @click="chooseNav(0)">  
        <p>选科概况
        </p>
        <div class="shadow">
        </div>
      </div>
      <div :class="{ act: this.nav_num == 1 }" @click="chooseNav(1)">
        <p>选科查询</p>
        <div class="shadow">
        </div>
      </div>
      <div :class="{ act: this.nav_num == 2 }" @click="chooseNav(2)">
        <p>智能选科</p>
        <div class="shadow">
        </div>
      </div>
      <div :class="{ act: this.nav_num == 3 }" @click="chooseNav(3)">
        <p>我的选科</p>
      </div>
    </div>
    <router-view @Nav="setNav"></router-view>
  </div>
</template>

<script>
export default {
  name: '',
  data() {
    return {
      nav_num: 0
    };
  },
  methods: {
    chooseNav(e) {
      this.nav_num = e

      switch (e) {
        case 0:
          this.$router.push("/newChooseSubject");
          return;
        case 1:
          this.$router.push("/newChooseSubject/newSpecialtySearch");
          return;
        case 2:
          this.$router.push("/newChooseSubject/chooseSpecialty");
          return;
        case 3:
          this.$router.push("/newChooseSubject/newSubjectUser");
          return;
      }
    },
    setNav(e) {
      this.nav_num = e;
    },
  },
};
</script>

<style scoped lang='less'>
.title {
  background-image: linear-gradient(to right, #fef6eb, #ffcfb1);
  padding: 30px;

  .f1 {
    color: #ab451d;
    font-size: 36px;
    font-weight: 500;
    letter-spacing: 3px;
  }

  .f2 {
    color: #666666;
    margin-top: 10px;
  }

  .f3 {
    color: black;
  }
}


.nav {
  margin-top: 20px;
  margin-bottom: 30px;
  border-radius: 30px;
  overflow: hidden;
  display: flex;
  justify-content: space-between;

  div {
    width: 200px;
    background: #fef6eb;
    padding: 12px 57px;
    position: relative;
    color: black;

    p {
      font-size: 16px;
    }

  }

  .act {
    color: white;
    background: #e08245;
  }

  .shadow {
    position: absolute;
    right: -6px;
    top: 0px;
    transform: skew(-15deg);
    background: #ffcfb1;
    // display: inline-block;
    width: 1px;
    height: 100%;
    padding: 1px 6px;
    z-index: 1;
  }
}
</style>
